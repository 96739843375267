import request from '@/plugins/axios'

// 群查询
export function userPush(current, limit, data) {
  return request({
    url: `/userPush/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 设置已读
export function readApi(id) {
  return request({
    url: `/userPush/${id}`,
    method: 'put'
  })
}
